import * as React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import Layout from '../layout'
import Seo from '../seo'
import PageBuilder from '../common/PageBuilder/PageBuilder'
import PageIntro from '../common/PageIntro'
import HeroImageWithText from '../common/PageBuilder/HeroImageWithText'
import HeroCarousel from '../common/PageBuilder/HeroCarousel'
import { Helmet } from 'react-helmet'

const Home = () => (
	<StaticQuery
		query={graphql`
			query MyQuery {
				strapiHome {
					id
					pageBuilder
					MetaDescription
					HeroCarousel {
						HeroCarouselSlide {
							Title
							paragraph
							ButtonLink {
								Slug
							}
							ButtonText
							HeroImage {
								provider_metadata {
									public_id
								}
							}
						}
					}
				}
			}
		`}
		render={(data) => (
			<Layout>
				<Helmet>
					<title>Delta Underwriting | Home</title>
					<meta name="description" content={data.strapiHome.MetaDescription} />
				</Helmet>
				<h1 class="visually-hidden"> Delta Underwriting Home</h1>
				<HeroCarousel data={data.strapiHome.HeroCarousel} />
				<PageBuilder data={data.strapiHome.pageBuilder} />
			</Layout>
		)}
	/>
)

export default Home
